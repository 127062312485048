<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>Business Travelller Details</strong>
            <back-to-list></back-to-list>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span
                @click="selected = 'Details'"
                v-if="checkPermission('list-business-traveller')"
            >
              <CTab active>
                <template slot="title">
                  {{ $lang.business_traveller.tab.details }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'BusinessResponse'"
                v-if="checkPermission('view-business-traveller-response')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.business_traveller.tab.response }}
                </template>
              </CTab>
            </span>
            <span
                @click="selected = 'BusinessReport'"
                v-if="checkPermission('view-business-traveller-report')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.business_traveller.tab.report }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component
              :is="selected"
              style="margin-top: 20px"
              v-bind:postId="currentId"
              @changeSelected="getTab($event)"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {Mixin} from "/src/mixins/index.js";
import Details from "./tab/Details";
import BusinessReport from "./tab/BusinessReport";
import BusinessResponse from "./tab/BusinessResponse";
import BackToList from "../backToList.vue";

export default {
  name: "BusinessTravellerView",
  mixins: [Mixin],
  components: {
    Details,
    BusinessReport,
    BusinessResponse,
    BackToList,
  },
  data() {
    return {
      currentId: "",
      userId: "",
      selected: "Details",
      backToListFlag: false,
    };
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.userId = this.$route.params.ids;
    self.currentId = id;
  },
  methods: {
    backToList(userId) {
      if (userId != null) {
        this.$router.push({path: "/profile/all/" + userId});
      }
    },
    cancel() {
      //  this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/books'})
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>

