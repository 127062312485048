<template>
  <div class="detail-div">
    <CRow>
      <div>
        <CModal
            :title="
            submitType === 'Update'
              ? $lang.post.crud.edit
              : $lang.common.general.reason
          "
            size="sm"
            :show.sync="largeModal"
            :no-close-on-backdrop="true"
            color="primary"
        >
          <CCardBody>
            <CCol md="12">
              <c-textarea
                  v-model="business_traveller.reason"
                  :rows="5"
                  :cols="10"
                  placeholder="Add Reason"
              ></c-textarea>
            </CCol>
            <CCol md="6">
              <div class="form-group">
                <CButton
                    color="primary"
                    v-c-tooltip="$lang.buttons.general.crud.submit"
                    :disabled="submitted"
                    v-on:click="blockPost(statusId)"
                >
                  Submit
                </CButton>
              </div>
            </CCol>
          </CCardBody>
          <template #footer style="display: none">
            <CButton
                @click="darkModal = false"
                color="danger"
                style="display: none"
            >Discard
            </CButton
            >
            <CButton
                @click="darkModal = false"
                color="success"
                style="display: none"
            >Accept
            </CButton
            >
          </template>
        </CModal>
      </div>

      <CCol md="12" class="align-self-center">

        <CRow>
          <CCol sm="6" md="4" lg="4">
            <!--            <p-->
            <!--              class="custome-labal"-->
            <!--              v-if="business_traveller.whose === 'Company'"-->
            <!--            >-->
            <!--              {{ $lang.business_traveller.form.company }}-->
            <!--            </p>-->
            <!--            <p class="custome-labal" v-if="business_traveller.whose === 'User'">-->
            <!--              {{ $lang.business_traveller.form.user }}-->
            <!--            </p>-->
            <p class="custome-labal">
              {{ $lang.common.form.user }}
            </p>
            <p class="custome-detail">
              <ProfilerDetail
                  :username="business_traveller.userWithCompany"
                  :profilerId="business_traveller.userId"
              ></ProfilerDetail>
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.industry }}
            </p>
            <p class="custome-detail">
              {{ business_traveller.industry }}
            </p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.subIndustry }}
            </p>
            <p class="custome-detail">{{ business_traveller.subIndustry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.fromDate }}
            </p>
            <p class="custome-detail">{{ business_traveller.fromDate }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.toDate }}
            </p>
            <p class="custome-detail">{{ business_traveller.toDate }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.fromCountry }}
            </p>
            <p class="custome-detail">{{ business_traveller.fromCountry }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.fromState }}
            </p>
            <p class="custome-detail">{{ business_traveller.fromState }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.fromCity }}
            </p>
            <p class="custome-detail">{{ business_traveller.fromCity }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.country }}
            </p>
            <p class="custome-detail">{{ business_traveller.country.name }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.state }}
            </p>
            <p class="custome-detail">{{ business_traveller.state.name }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.city }}
            </p>
            <p class="custome-detail">{{ business_traveller.city }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.area }}
            </p>
            <p class="custome-detail">{{ business_traveller.area }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.meetOver }}
            </p>
            <p class="custome-detail" v-show="meetOver">{{ meetOver }}</p>
          </CCol>
          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.decisionMaker }}
            </p>
            <p class="custome-detail">{{ decisionMaker }}</p>
          </CCol>

          <CCol sm="6" md="4" lg="4">
            <p class="custome-labal">
              {{ $lang.business_traveller.form.status }}
            </p>
            <p class="custome-detail">
              <CBadge :color="statusBudget(business_traveller.status)">{{ business_traveller.status }}</CBadge>
            </p>
          </CCol>
        </CRow>
        <entity-time-stamp :createdAt="business_traveller.createdAt"
                           :updatedAt="business_traveller.updatedAt"></entity-time-stamp>
        <CRow>
          <CCol sm="12" align="left">
            <CButton
                :color="statusColor"
                v-c-tooltip="$lang.common.general.status"
                v-on:click="beforeBlocked()"
            >
              {{ statusLabel }}
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import store from "/src/store/store.js";
import {
  businessTraveller,
  industry,
  users,
  profileSubIndustry,
  country,
  state,
  city,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import moment from "moment";
import ProfilerDetail from "/src/views/ProfilerDetail.vue";
import EntityTimeStamp from "/src/components/EntityTimeStamp";

export default {
  name: "BusinessTravellerEdit",
  mixins: [Mixin],
  components: {
    ProfilerDetail,
    EntityTimeStamp
  },
  data() {
    return {
      largeModal: false,
      submitType: "",
      statusId: "",
      statusLabel: "Block",
      statusColor: "danger",
      submitted: false,
      Disabled: true,
      decisionMaker: "",
      meetOver: "",
      err_msg: "",
      profileImage: "",
      dismissCountDown: 0,
      selectedfromDate: "",
      selectedtoDate: "",
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      statusOption: [],
      userOptions: [],
      getSubIndustries: [],
      getIndustries: [],
      getDBIndustry: [],
      getDBSubIndustry: [],
      self: this,
      module: businessTraveller,
      moduleIndustry: industry,
      moduleSubIndustry: profileSubIndustry,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      business_traveller: {
        area: "",
        fromDate: "",
        toDate: "",
        industry: "",
        subIndustry: "",
        country: "",
        state: "",
        city: "",
        user: "",
        status: "",
        isActive: "",
        isStatus: "",
        userId: "",
        whose: "",
        reason: "",
        userWithCompany: "",
        createdAt: "",
        updatedAt: '',
      },
    };
  },
  computed: {},

  mounted() {
    let self = this;
    self.statusOption.push(
        {value: "1", label: "Active"},
        {value: "0", label: "InActive"},
        {value: "2", label: "Block"},
        {value: "3", label: "Closed"}
    );
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          if (response.data) {
            let responseData = response.data;
            self.business_traveller.fromDate = responseData.fromDate ?? ''; // moment(responseData.fromDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
            self.business_traveller.toDate = responseData.toDate ?? ''; // moment(responseData.toDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

            self.business_traveller.area = responseData.area ?? '';
            self.business_traveller.isActive = responseData.isActive ?? '';
            self.decisionMaker = responseData.decisionMakers ?? '';
            self.meetOver = responseData.meetOver ?? '';
            self.business_traveller.isStatus = responseData.isActiveStatus ?? '';

            self.business_traveller.user = responseData.user.userFirstName ?? '';
            self.business_traveller.userWithCompany = responseData.user.userWithCompany ?? '';

            self.business_traveller.userId = responseData.user.id ?? '';
            self.business_traveller.status = responseData.isActiveStatus ?? '';
            self.business_traveller.country = responseData.country ?? '';
            self.business_traveller.state = responseData.state ?? '';
            self.business_traveller.city = responseData.city ?? '';
            self.business_traveller.fromCountry = responseData.fromCountryName ?? '';
            self.business_traveller.fromState = responseData.fromStateName ?? '';
            self.business_traveller.fromCity = responseData.fromCityName ?? '';
            self.business_traveller.createdAt = responseData.createdAt ?? '';
            self.business_traveller.updatedAt = responseData.updatedAt ?? '';

            if (self.business_traveller.isStatus === "Active") {
              self.statusLabel = this.getStatusLabel(2);

              self.statusId = "2";
              self.statusColor = "danger";

            } else {
              self.statusId = "1";
              self.statusLabel = this.getStatusLabel(1);
              self.statusColor = "success";
            }

            let listIndustry = [];
            responseData.industry.map(function (value, key) {
              listIndustry.push(value.title);
            });
            self.business_traveller.industry = listIndustry.join(", ");
            let listSubIndustry = [];
            responseData.subIndustry.map(function (value, key) {
              listSubIndustry.push(value.title);
            });
            self.business_traveller.subIndustry = listSubIndustry.join(", ");

            self.business_traveller.whose = responseData.user.whose;
            if (responseData.user.company)
              self.business_traveller.user = responseData.user.company;
            else if (responseData.user.name)
              self.business_traveller.user = responseData.user.name;
          }
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function () {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    getStatusLabel(getStatus) {
      let valObj = " ";
      valObj = this.statusOption.filter(function (elem) {
        if (elem.value == getStatus) return elem.value;
      });
      return valObj[0].label ?? '';
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    beforeBlocked() {
      let self = this;
      self.business_traveller.reason = "";
      this.largeModal = true;
    },

    blockPost(statusId) {
      let self = this;
      self.submitted = true; //Enable Button
      if (self.business_traveller.reason === "") {
        self.submitted = false; //Enable Button
        return false;
      }
      this.$swal({
        title: this.$lang.common.status.want_to_block,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.status.yes_block,
        cancelButtonText: this.$lang.common.status.cancel,
      }).then((result) => {
        if (result.value) {
          const id = this.$route.params.id;

          setTimeout(() => {
            let self = this;
            const postData = {
              status: self.statusId,
              reason: self.business_traveller.reason,
            };
            let status, button;
            if (statusId === "1") {
              self.statusId = "2";
              self.statusColor = "danger";
              status = this.getStatusLabel(1);
              button = "Block";
            } else {
              self.statusId = "1";
              self.statusColor = "success";
              status = "Blocked";
              button = this.getStatusLabel(1);
            }
            axios
                .post(this.statusUrlWeb(this.module, id), postData)
                .then(function (response) {
                  if (response.data.code === 200) {
                    self.business_traveller.status = status;
                    self.statusLabel = button;
                    self.largeModal = false;
                    self.submitted = false; //Enable Button
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      });
    },
  },
};
</script>
