<template>
  <CRow>
    <div v-if="submitType === 'Update'">
      <CModal
          :title="
          submitType === 'Update'
            ? $lang.business_traveller_report.crud.edit
            : $lang.business_traveller_report.crud.create
        "
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
            err_msg
          }}
        </CAlert>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <div class="form-group" :class="{ 'form-group--error': '' }">
              <label class="form__label"
              >{{ $lang.business_traveller_report.form.reason }}
                <required_span/>
              </label>
              <v-select
                  :options="reasonOptions"
                  v-model="business_traveller_report.reason"
              ></v-select>
              <small
                  class="error"
                  v-if="
                  $v.business_traveller_report.reason.$error &&
                  !$v.business_traveller_report.reason.required
                "
              >
                {{ $lang.business_traveller_report.validation.required.reason }}
              </small>
            </div>

            <div class="form-group">
              <label class="form__label">{{
                  $lang.business_traveller_report.form.description
                }}</label>
              <textarea
                  class="form-control"
                  v-model="business_traveller_report.description"
              />
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
          </CCardFooter>
        </CForm>
        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>
    <div v-else>
      <CModal
          :title="
          submitType === 'Update'
            ? $lang.business_traveller_report.crud.view
            : $lang.business_traveller_report.crud.view
        "
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        <div class="form-group" :class="{ 'form-group--error': '' }">
          <label class="form__label">
            {{ $lang.business_traveller_report.form.reason }} :
          </label>
          <div>
            {{ business_traveller_report.reason.label }}
          </div>
        </div>
        <div class="form-group">
          <label class="form__label"
          >{{ $lang.business_traveller_report.form.description }} :
          </label>
          <div>
            {{ ViewResponse }}
          </div>
        </div>
        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>
    <CCol col="12">
      <div class="row">
        <CCol sm="6" class="align-self-center">
          <strong>{{
              $lang.business_traveller_report.social_title
            }}</strong>
        </CCol>
        <CCol sm="6" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="
                      exports(
                        self,
                        'pdf',
                        module,
                        'Business Travellers Report',
                        self.editId
                      )
                    "
              >
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title"
              >
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <!--        {{ showAlert }}-->
      <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
      <CAlert
          color="success"
          closeButton
          :show.sync="dismissCountDownS"
          fade
      >{{ alertMessage }}
      </CAlert
      >

      <div id="people">
        <v-server-table
            :columns="columns"
            :url="listUrl"
            :options="options"
            ref="myTable"
            @loaded="
                exports(
                  self,
                  'Excel',
                  module,
                  'Business Travellers Report',
                  self.editId
                )
              "
        >
          <template #isActive="data">
            <CBadge :color="statusBudget(data.row.isActive)">{{ data.row.isActive }}</CBadge>
          </template>
          <template #actions="data">
            &nbsp;
            <CButton
                color="primary"
                v-c-tooltip="$lang.business_traveller_report.crud.view"
                v-on:click="viewResponse(data.row.id)"
            >
              <i class="fas fa-eye"></i>
            </CButton>
            <!--                <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit"-->
            <!--                         v-on:click="editTableRow(data.row.id)">-->
            <!--                  <i class="fas fa-pencil-alt"></i>-->
            <!--                </CButton>-->
          </template>
        </v-server-table>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {BTReportList, reportreason} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "/src/components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "BusinessReport",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      ViewResponse: "",
      submitType: "",
      reportId: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      reasonOptions: [],
      activePage: 1,
      listUrl: "",
      module: BTReportList,
      moduleReportReason: reportreason,
      business_traveller_report: {
        reason: "",
        description: "",
        isActive: "",
      },

      columns: ["reason", "username", "isActive", "actions"],
      data: [],
      options: {
        headings: {
          reason: this.$lang.business_traveller_report.table.reason,
          username: this.$lang.business_traveller_report.table.username,
          isActive: this.$lang.common.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },

  validations: {
    business_traveller_report: {
      reason: {
        required,
      },
    },
  },
  props: ["postId"],
  created() {
    let self = this;
    self.editId = this.postId;
    self.listUrl = "/business-traveller/report/list/" + self.editId;
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.reportId = id;
    self.editId = id;
    self.editId = this.postId; // from tab using props
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    axios.get(this.listUrlApi(this.moduleReportReason)).then((response) => {
      response.data.data.map(function (value, key) {
        self.reasonOptions.push({value: value.id, label: value.title});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.business_traveller_report.isVerified = 1;
      } else {
        self.business_traveller_report.isVerified = 0;
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      this.$router.push({name: "business_traveller_report Create"});
    },
    viewResponse(item) {
      let self = this;
      self.reportId = item;
      axios
          .get(this.viewUrlApi(this.module, self.reportId), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data;
              self.ViewResponse = responseData.data.description;
              self.business_traveller_report.reason = {
                value: responseData.data.reasonId,
                label: responseData.data.reason,
              };
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      self.reportId = item;
      axios
          .get(this.viewUrlApi(this.module, self.reportId), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data;
              self.business_traveller_report.reason = {
                value: responseData.data.reasonId,
                label: responseData.data.reason,
              };
              self.business_traveller_report.description =
                  responseData.data.description;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    rowClicked(item, index) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },

    onSubmit() {
      let self = this;
      this.$v.business_traveller_report.$touch();
      if (this.$v.business_traveller_report.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              reason: self.business_traveller_report.reason.value,
              description: self.business_traveller_report.description,
              id: self.reportId,
            };
            axios
                .post(this.updateUrlweb(this.module, self.reportId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.response.message;
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.response.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              socialLink: self.business_traveller_report.SocialLink,
              plateform: self.business_traveller_report.platform,
              isVerified: self.business_traveller_report.isVerified,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.response.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.response.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
